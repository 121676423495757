// /* eslint-disable react-hooks/rules-of-hooks */
// import React from "react";
// import MixpanelRecord from "../MixpanelEvent";

// import { useEffect } from "react";

// export default function index() {
//   useEffect(() => {
//     MixpanelRecord("Utile Dulci Page", {
//       message: "Utile Dulci Page",
//     });
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);
//   // let userEmail = sessionStorage.getItem("useremail");
//   // console.log(userEmail, "email");
//   const handleScroll = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     return false;
//   };
//   let userEmail = "";
//   if (typeof sessionStorage !== "undefined") {
//     userEmail = sessionStorage.getItem("useremail");
//   }

//   return (
//     <div>
//       {/* <Header></Header> */}
//       <iframe
//         className="w-full overflow-hidden	"
//         style={{ border: "none", height: "88vh" }}
//         title="Utile Dulci"
//         // src="http://172.190.72.48:9998/"
//         // src={`http://172.190.72.48:9996/?email=${userEmail}`}
//         src={`http://172.190.72.48:9996/?email=${userEmail}`}
//         // width="1365"
//         // height="640"
//       ></iframe>
//     </div>
//   );
// }

import React, { useEffect } from "react";
import MixpanelRecord from "../MixpanelEvent";

export default function Index() {
  useEffect(() => {
    MixpanelRecord("Utile Dulci Page", {
      message: "Utile Dulci Page",
    });

    // Add event listener to prevent scroll on the body
    document.body.style.overflow = "hidden";

    // Clean-up function to remove event listener
    return () => {
      document.body.style.overflow = ""; // Restore scroll
    };
  }, []);

  let userEmail = "";
  if (typeof sessionStorage !== "undefined") {
    userEmail = sessionStorage.getItem("useremail");
  }

  return (
    <div>
      {/* Content of your component */}
      <iframe
        className="w-full overflow-x-hidden"
        style={{ border: "none", height: "100vh", marginTop: "-1%" }}
        title="Utile Dulci"
        src={`http://172.190.72.48:9996/?email=${userEmail}`}
      ></iframe>
    </div>
  );
}
