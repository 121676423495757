export default function getCookieValue(cookieName) {
  try {
    var cookieValue = document.cookie.match(
      "(^|;)\\s*" + cookieName + "\\s*=\\s*([^;]+)"
    );
    return cookieValue ? decodeURIComponent(cookieValue.pop()) : "";
  } catch (e) {
    console.log(e);
  }
}
