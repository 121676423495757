import React from "react";
import "./scss/main.css";
// import './../pages/app.css'
import "./Team.css";
import "./Research/Povs";
// import './../pages/PresalesOfferingshub.css';
import "./../pages/Presales/Hub.css";
import "./../pages/Research/Market.css";
import "./../pages/Research/Publications.css";
import "./Whitepapers.css";
import "./../pages/Presales/Videos.css";
import "./../pages/Presales/Casestudies.css";
import "../styles/Total.css";
import "../styles/learning.css";
import "../styles/Reading.css";
import "../pages/Slidemenu.css";
import { useRouter } from "next/router";
import Sidenav from "./Sidenav/index";

export default function App({ Component, pageProps }) {
  const router = useRouter();
  const showHeader = router.pathname == "/" ? false : true;
  // console.log(showHeader);
  const isLoginPage = ["/Login","/","/Signup"];
  const excludeTopBarFromStartingPage =isLoginPage.includes(router.pathname);


  const pageStyles = {
    loginPage: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      
    },
    otherPages: {
     
      paddingTop: "4.5%"
      
    },
  };
  
  return (
    <div style={{width:"100%"}}>
      {router.pathname !== "/" && !excludeTopBarFromStartingPage && <Sidenav />}
     

      {/* <div className="grid"> */}
        <div
          
          style={excludeTopBarFromStartingPage ? pageStyles.loginPage : pageStyles.otherPages}
        >
          <Component {...pageProps} />
         

          {/* <Policy/> */}
        </div>
        {/* <Policy/> */}
      {/* </div> */}
    </div>
  );
}
