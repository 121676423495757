import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { useRouter } from "next/router";
import Image from "next/image";

import Link from "next/link";
import { Button } from "primereact/button";

export default function Povs() {
  const router = useRouter();
  const {
    query: { username, policy },
  } = router;

  const props = {
    username,
    policy,
  };

  const name = props.username;

  // useEffect(() => {
  //   const button = document.querySelector("button");
  //   button.addEventListener("click", (e) => {

  //     document
  //       .querySelector(".element")
  //       .classList.toggle("slide-out-bck-center");
  //   });
  // });

  // const header = (
  //   <Image
  //     alt="Card"
  //     src="http://digitalstudio.eastus.cloudapp.azure.com/images/market.jpg"
  //     width={300}
  //     height={150}
  //     className="img1"
  //   ></Image>
  // );
  // const footer = (
  //   <div>
  //     <Link href="/Market">Explore</Link>
  //   </div>
  // );
  return (
    <>
      <Image
        style={{ marginTop: 20, borderRadius: 5 }}
        src="http://digitalstudio.eastus.cloudapp.azure.com/images/Banking-as-a-lifestyle.jpg"
        quality="100"
        width={610}
        height={400}
      ></Image>

      <Card
        style={{
          top: -380,
          width: 545,
          height: 350,
          left: 30,
          position: "relative",
          opacity: 0.8,
          borderRadius: 5,
          background: "rgba(0, 0, 0, 0.1)",
          backgroundColor: "whitesmoke",
        }}
      >
        <h2 style={{ padding: 0, marginTop: 0 }}>POV's</h2>
        <div class="flex" style={{ marginLeft: 20, marginTop: -25 }}>
          <div class="col-2">
            <Card
              className="paperpov"
              style={{
                width: 120,
                height: 100,
                textAlign: "center",
                marginTop: 30,
                fontSize: 18,
                fontWeight: "700",
                marginTop: 20,
                color: "white",
                backgroundColor: "rgba(0, 134, 179)",
              }}
              // onClick={() => router.push("/Market")}
              onClick={() =>
                router.push(
                  {
                    pathname: "/Research/Market",

                    query: { username, policy },
                  },

                  "/Research/Market"
                )
              }
            >
              {" "}
              <p> Market</p>
            </Card>
          </div>
          <div class="col-2 col-offset-2">
            <Card
              className="paperpov"
              style={{
                width: 120,
                height: 100,
                textAlign: "center",
                marginTop: 30,
                fontSize: 18,

                fontWeight: "700",
                marginTop: 20,
                color: "white",
                backgroundColor: "rgba(0, 134, 179)",
              }}
              // onClick={() => router.push("/Account")}
              onClick={() =>
                router.push(
                  {
                    pathname: "/Research/Account",

                    query: { username, policy },
                  },

                  "/Research/Account"
                )
              }
            >
              {" "}
              <p> Account</p>
            </Card>
          </div>
          <div class="col-2 col-offset-2">
            <Card
              className="paperpov"
              style={{
                width: 120,
                height: 100,
                textAlign: "center",
                marginTop: 30,
                fontSize: 18,

                fontWeight: "700",
                marginTop: 20,
                color: "white",
                backgroundColor: "rgba(0, 134, 179)",
              }}
              // onClick={() => router.push("/Offerings")}
              onClick={() =>
                router.push(
                  {
                    pathname: "/Research/Offerings",

                    query: { username, policy },
                  },

                  "/Research/Offerings"
                )
              }
            >
              {" "}
              <p> Offerings</p>
            </Card>
          </div>
        </div>
        <div class="flex">
          <div class="col-2 col-offset-2">
            <Card
              className="paperpov"
              style={{
                width: 130,
                height: 100,
                textAlign: "center",
                marginTop: 30,
                fontSize: 18,

                fontWeight: "700",
                marginTop: 20,
                color: "white",
                backgroundColor: "rgba(0, 134, 179)",
              }}
              // onClick={() => router.push("/Competitors")}
              onClick={() =>
                router.push(
                  {
                    pathname: "/Research/Competitors",

                    query: { username, policy },
                  },

                  "/Research/Competitors"
                )
              }
            >
              {" "}
              <p> Competitors</p>
            </Card>
          </div>
          <div class="col-2 col-offset-2">
            <Card
              className="paperpov"
              style={{
                width: 130,
                height: 100,
                textAlign: "center",
                marginTop: 30,
                fontSize: 18,

                fontWeight: "700",
                marginTop: 20,
                color: "white",
                backgroundColor: "rgba(0, 134, 179)",
              }}
              // onClick={() => router.push("/Confidential")}
              onClick={() =>
                router.push(
                  {
                    pathname: "/Research/Confidential",

                    query: { username, policy },
                  },

                  "/Research/Confidential"
                )
              }
            >
              {" "}
              <p> Confidential </p>
            </Card>
          </div>
        </div>
      </Card>

      {/* <Image
          src="http://digitalstudio.eastus.cloudapp.azure.com/images/Banking-as-a-lifestyle.jpg"
          width={610}
          height={700}
        >
          <p>hi</p>
        </Image> */}
    </>
  );
}
