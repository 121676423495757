import mixpanel from "mixpanel-browser";
import getCookieValue from "./GetCookie";

mixpanel.init("ac32ed2fa513ac4b03d41dc13745c51c", { debug: true }); //harvisV1
//dev: 8c8174b74cfe9b047c9ee1d13503c638
//prod : ac32ed2fa513ac4b03d41dc13745c51c

export default function MixpanelRecord(eventname, eventobj) {
  eventobj.user = getCookieValue("user");
  eventobj.LoginStatus = getCookieValue("LoginStatus");
  eventobj.Designation = getCookieValue("Designation");

  // console.log(eventobj)
  // console.log(getCookieValue("LoginStatus"))
  mixpanel.track(eventname, eventobj);
}
