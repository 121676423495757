import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Image from "next/image";
import { useRouter } from "next/router";
const LogoutConfirmation = ({ onConfirm }) => {
    const [visible, setVisible] = useState(false);
    const router = useRouter();

    const handleLogout = () => {
    
        router.push({

            pathname: "/",
      
          });
      onConfirm();
    };
  
    const confirmLogout = () => {
      setVisible(true);
    };
  
    const hideDialog = () => {
      setVisible(false);
    };
  
    return (
      <div>
         <Image
            alt="logo"
            src="http://digitalstudio.eastus.cloudapp.azure.com/images/logout.png"
            width={30}
            height={10}
            style={{ height: 30,position:"fixed",top:17,right:11,cursor:"pointer"}}
            onClick={confirmLogout}
          ></Image>
        {/* <Button label="Logout" onClick={confirmLogout} /> */}
        <Dialog
          visible={visible}
          onHide={hideDialog}
          header="Confirm Logout"
          footer={
            <div>
              <Button label="Yes" onClick={handleLogout} className="p-button-danger" />
              <Button label="No" onClick={hideDialog} className="p-button-secondary" />
            </div>
          }
        >
          <p>Are you sure you want to logout?</p>
        </Dialog>
      </div>
    );
  };
  
  export default LogoutConfirmation;
  